import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsPage from "./Terms";
import PrivacyPage from "./Privacy";
import CopyrightPage from "./Copyright";
import "./App.css";
import Logo from "./Logo.png";
import BackgroundLottie from "./BackgroundLottie.js";
import { projectConfiguration } from "./projectConfiguration";

const API_DOMAIN = "https://api.hightide.link/ideas";
const { productKey, heroLineOne, heroLineTwo, heroLineThree, subtitle } = projectConfiguration;
const PRODUCT_KEY = productKey;

const LandingPage = () => {
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [invitations, setInvitations] = useState(["", ""]);
  const [invitationsSent, setInvitationsSent] = useState(0);
  const [waitlistNumber, setWaitlistNumber] = useState();
  const [error, setError] = useState();

  const onSubmitJoinWaitlist = () => {
    setError(false);
    const data = {
      productKey: PRODUCT_KEY,
      email: emailInputValue,
      referrals: [],
    };

    fetch(`${API_DOMAIN}/joinWaitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          setSubmittedEmail(emailInputValue);
          setWaitlistNumber(responseData?.waitlistNumber);
        } else {
          setError("An error occurred joining the waitlist");
        }
      })
      .catch((error) => {
        setSubmittedEmail(emailInputValue);
        setError(error);
        console.error("Error making POST request:", error);
      });
  };

  const onSubmitInvitations = () => {
    setError(false);
    const nonEmptyInvitations = invitations.filter((element) => element !== "");
    const data = {
      productKey: PRODUCT_KEY,
      email: submittedEmail,
      referrals: nonEmptyInvitations,
    };

    fetch(`${API_DOMAIN}/joinWaitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("POST request successful:", responseData);
        setInvitationsSent(invitationsSent + nonEmptyInvitations.length);
        setInvitations(["", ""]);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleInvitationInputChange = (index, value) => {
    const updatedValues = [...invitations];
    updatedValues[index] = value;
    setInvitations(updatedValues);
  };

  const handleAddInvitation = () => {
    setInvitations([...invitations, ""]);
  };

  const variableSectionStyle = {
    backgroundColor: projectConfiguration.backgroundColor,
    color: projectConfiguration.primaryTextColor,
  };

  const variableContainerStyle = {
    fontFamily: projectConfiguration.secondaryFont,
  };

  const variableHeroTextStyle = {
    fontFamily: projectConfiguration.primaryTextColor,
  };

  const variableSubtitleTextStyle = {
    color: projectConfiguration.tertiaryTextColor,
  };

  const variableInputStyle = {
    backgroundColor: projectConfiguration.inputBackgroundColor,
    color: projectConfiguration.secondaryTextColor,
  };

  const variableInputFocusStyle = {
    backgroundColor: projectConfiguration.inputFocusBackgroundColor,
    color: projectConfiguration.inputFocusTextColor,
  };

  const variableButtonStyle = {
    backgroundColor: projectConfiguration.punchColor,
    color: projectConfiguration.buttonTextColor,
  };

  const variableDisclosureTextStyle = {
    color: projectConfiguration.secondaryTextColor,
  };

  const variableLinkStyle = {
    color: projectConfiguration.punchColor,
  };

  const variableFooterLinkStyle = {
    color: projectConfiguration.footerLinkColor,
  };

  function handleKeyDown(e) {
    if (e.key == 'Enter') {
      const submitCb = !!submittedEmail ? onSubmitInvitations : onSubmitJoinWaitlist;
      submitCb();
    }
  }
  return (
    <div className="container" style={variableContainerStyle} onKeyDown={handleKeyDown}>
      <div className="mainContent">
        <div className="section" style={variableSectionStyle}>
          <div className="wordsContent">
            <div
              style={{
                marginRight: 16,
              }}
            >
              <img className="logo" src="/images/logomark.png" />
              {!!waitlistNumber && <>
                <h1 className={"heroSubmittedText"}>Thanks for joining.  Now invite some friends!</h1>
                <h2>🎉{`  You are invite number ${waitlistNumber - invitationsSent} `}</h2>
                </>}
              {!!waitlistNumber && !invitationsSent && <>
                  <h4>Invite 2 friends to move up to the top of the list!</h4>
                </>
              }
              {!!waitlistNumber && !!invitationsSent && (
                <h4>
                  You’ve moved up the list! Keep inviting to move higher.
                </h4>
              )}
              {!!error && <p>{error}</p> }
            </div>
            {submittedEmail ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {invitations.map((value, index) => (
                  <div className="inputContainer">
                    <input
                      key={index}
                      className="emailInput"
                      style={variableInputStyle}
                      placeholder="Enter your friend's email"
                      type="email"
                      value={value}
                      onChange={(e) => {
                        handleInvitationInputChange(index, e.target.value);
                      }}
                    />
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignSelf: "flex-end",
                    marginRight: 16,
                    cursor: "pointer",
                  }}
                >
                  <p onClick={handleAddInvitation}>+ Add another invitation</p>
                </div>
                <button
                  className="emailButton"
                  style={{ marginRight: 16 }}
                  onClick={onSubmitInvitations}
                >
                  Invite Friends
                </button>
              </div>
            ) : (<>
              <h1 className="heroText" style={variableHeroTextStyle}>
            {heroLineOne}
          </h1>
          <h1 className="heroText" style={variableHeroTextStyle}>
            {heroLineTwo}
          </h1>
          <h1 className="heroText" style={variableHeroTextStyle}>
            {heroLineThree}
          </h1>
          <p className="subtitleText" style={variableSubtitleTextStyle}>
            {subtitle}
          </p>
              <div className="inputContainer">
                <input
                  className="emailInput"
                  style={variableInputStyle}
                  onFocus={(e) =>
                    (e.target.style = {
                      ...variableInputStyle,
                      ...variableInputFocusStyle,
                    })
                  }
                  onBlur={(e) =>
                    (e.target.style = {
                      ...variableInputStyle,
                    })
                  }
                  placeholder="Enter your email"
                  type="email"
                  value={emailInputValue}
                  onChange={(e) => setEmailInputValue(e.target.value)}
                />
                <button
                  className="emailButton"
                  style={variableButtonStyle}
                  onClick={onSubmitJoinWaitlist}
                >
                  Join Waiting List
                </button>
              </div>
            </>)}
            <p className="disclosureText" style={variableDisclosureTextStyle}>
              *by entering your e-mail address you confirming that you’re agree
              with our{" "}
              <a className="link" style={variableLinkStyle} href="/terms">
                Terms of Service
              </a>
              .
            </p>
          </div>
        </div>
        <div className="sectionPhone">
          <img className="screenshot" src="/images/iPhone.png" />
        </div>
      </div>
      <div className="footer">
        <a className="link" style={variableFooterLinkStyle} href="mailto:help@testco.de">
          Contact Us
        </a>
        <a className="link" style={variableFooterLinkStyle} href="/terms">
          Terms
        </a>
        <p>© 2023. All rights reserved.</p>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<LandingPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/copyright" element={<CopyrightPage />} />
      </Routes>
    </Router>
  )
}
