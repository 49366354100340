// The hero image can be added to the src folder with the HeroImage.png name.
// Logo image can be added to the src folder as Logo.png
// Favicon image can be added to the public folder as favicon.ico

// Project specific values that can be changed easily.
export const projectConfiguration = {
  // Product Key
  productKey: "seen",

  // Content variables
  heroLineOne: "Get Authentic",
  heroLineTwo: "Reactions",
  heroLineThree: "From Friends",
  subtitle:
    "See what your friends REALLY think of your videos...👀",

  // Colors
  backgroundColor: "rgb(114, 54, 201, 0)",
  primaryTextColor: "#ffffff",
  secondaryTextColor: "#9FA4BC",
  tertiaryTextColor: "#D0D4EA",
  inputBackgroundColor: "white",
  inputFocusBackgroundColor: "#ffffff",
  inputTextColor: "#595D6F",
  inputFocusTextColor: "#0D0F14",
  punchColor: "#F504F5", // Used as link color
  footerLinkColor: "#ffffff",
  buttonTextColor: "#ffffff",

  //   Fonts
  primaryFont: "Poppins",
  secondaryFont: "Inter",
};
